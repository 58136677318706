import * as React from "react";
import styled from "@emotion/styled";
import { screenSize, colors } from "../../utils/css/themes";
import { Link } from "gatsby";
import DangerousHtml from "../Helpers/DangerousHtml";
import Confetti from "../../utils/images/bg-confetti.svg";
import { useUrlParse } from "../../utils/useUrlParse";

const Container = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 60em 11.5em;
  border-bottom-left-radius: 31em 2.5em;
  position: relative;
  z-index: 2;
  margin-left: -2rem;
  padding-left: 2rem;
  margin-right: -5rem;
  padding-right: 5rem;
  margin-bottom: -8rem;
  @media (max-width: ${screenSize.largeTablet}) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  height: 100%;
  max-width: 1160px;
  width: 1160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 1160px) {
    width: 100%;
    max-width: 100%;
    right: auto;
    left: auto;
  }
`;

const Text = styled.div`
  position: absolute;
  color: white;
  width: 50%;
  left: 2rem;
  bottom: 5rem;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 1);
  h2 {
    font-size: 70px;
    color: white;
  }
  p {
    font-size: 36px;
    line-height: 1.11;
    color: white;
  }

  @media (max-width: ${screenSize.largeTablet}) {
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
    margin-right: 4rem;
    padding-top: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: center;
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${screenSize.largePhone}) {
    padding-top: 0;
  }
`;

const LearnMoreLink = styled((props) => <Link {...props} />)`
  width: 140px;
  height: 40px;
  text-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  border-radius: 20px;
  background-color: #ffffff;
  @media (max-width: ${screenSize.largeTablet}) {
    margin: auto;
  }
  @media (max-width: ${screenSize.largePhone}) {
    position: relative;
    bottom: 20px;
  }
  &:hover {
    color: white;
    background-color: ${colors.blue};
    cursor: pointer;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  max-height: 586px;

  background: ${colors.green} url(${Confetti});

  @media (max-width: ${screenSize.largeTablet}) {
    height: 300px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  @media (max-width: ${screenSize.largePhone}) {
    height: 100%;
    width: auto;
  }
`;

const MainImage = (props: PropsMainImage) => {
  return (
    <Container>
      <VideoContainer>
        <Video id="background-video" loop muted autoPlay playsInline>
          <source src={props.video2} type="video/webm" />
          <source src={props.video} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      </VideoContainer>
      <TextContainer>
        <Text>
          <DangerousHtml>{props.content}</DangerousHtml>
          <LearnMoreLink to={useUrlParse(props.button_link)}>
            {props.button_text}
          </LearnMoreLink>
        </Text>
      </TextContainer>
    </Container>
  );
};

interface PropsMainImage {
  content: any;
  video: string;
  video2: string;
  button_text: string;
  button_link: string;
}

export default MainImage;
