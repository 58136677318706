import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize, quicksandFont } from "../../utils/css/themes";
import Arrow from "../../utils/images/HomePage/OurImpact/arrow.inline.svg";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const ImpactContainer = styled.div`
  background-color: ${colors.blue};
  padding: 7rem 0;
`;

const ImpactContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 1160px;
  margin: auto;
`;

const BlockTop = styled.div`
  margin-top: 6rem;
  margin-bottom: 1rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${screenSize.largePhone}) {
    padding-left: 1.5rem;
  }
`;

const SubTop = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const BlockMiddle = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  @media (max-width: ${screenSize.largeTablet}) {
    flex-flow: row wrap;
    margin: 0 11%;
  }

  @media (max-width: ${screenSize.largePhone}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }
`;

const ImpactLabel = styled.div`
  font-family: Baloo;
  font-size: 50px;
  line-height: 0.76;
  letter-spacing: -2px;
  text-align: right;
  color: #ffffff;
`;

const ImpactSubLabel = styled.div`
  font-family: TragicMarker;
  font-size: 22px;
  line-height: 1.73;
  padding-left: 1rem;
  letter-spacing: normal;
  color: ${colors.green};
  text-align: right;
  @media (max-width: ${screenSize.largePhone}) {
    display: none;
  }
`;

const ImpactBox = styled.div`
  width: 270px;
`;

const NumberText = styled.div`
  font-family: TragicMarker;
  font-size: 72px;
  color: ${colors.yellow};
  text-align: center;
  margin-top: 1rem;
`;

const DescriptionText = styled.div`
  font-family: ${quicksandFont.regular};
  font-size: 24px;
  padding: 0 35px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 2.4px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2.7rem;
  padding: auto;
  text-transform: uppercase;
`;

const Images = styled.div`
  text-align: center;
  margin-bottom: 3.4rem;
`;

const ArrowImage = styled.span`
  transform: scaleX(-1) rotate(-75deg) translateX(4px) translateY(15px);
  @media (max-width: ${screenSize.largePhone}) {
    transform: scaleX(-1) rotate(-75deg) translateX(4px) translateY(8px);
  }
`;

const LearnMoreLink = styled((props: any) => <Link {...props} />)`
  text-decoration: none;
  color: ${colors.blue};
  font-family: ${quicksandFont.regular};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  border-radius: 20px;
  width: 25%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto 3rem auto;
  &:hover {
    color: ${colors.blue};
    background-color: ${colors.yellow};
    cursor: pointer;
  }
`;

const ImgContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
`;

const OurImpact = (props: PropsOurImpact) => {
  function impactArrow() {
    return (
      <ArrowImage>
        <Arrow />
      </ArrowImage>
    );
  }

  function impactBoxes() {
    return props?.our_impact?.map((impact: any, i: number) => (
      <ImpactBox key={i}>
        <ImgContainer>
          {impact.image && impact.image.localFile && (
            <GatsbyImage
              image={impact.image.localFile.childImageSharp.gatsbyImageData}
              alt={""}
            />
          )}
        </ImgContainer>
        <NumberText>{impact.number}</NumberText>
        <DescriptionText>{impact.description}</DescriptionText>
      </ImpactBox>
    ));
  }

  return (
    <ImpactContainer>
      <ImpactContent>
        <BlockTop>
          {impactArrow()}
          <ImpactLabel>{"Our Impact"}</ImpactLabel>
          <SubTop>
            <ImpactSubLabel>{"So far"}</ImpactSubLabel>
          </SubTop>
        </BlockTop>
        <BlockMiddle>{impactBoxes()}</BlockMiddle>
        <LearnMoreLink to="/about-us/our-impact/">Learn More</LearnMoreLink>
      </ImpactContent>
    </ImpactContainer>
  );
};

interface PropsOurImpact {
  our_impact: any;
}
export default OurImpact;
