import * as React from "react";
import styled from "@emotion/styled";
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import ArrowButtonContainer from "./ArrowButtonContainer";
import { colors, quicksandFont } from "../../utils/css/themes";
import { useStaticQuery, graphql } from "gatsby";
import ExpandOnHover from "./ExpandOnHover";
import DangerousHtml from "./DangerousHtml";

const SuccessImage = styled((props: any) => <GatsbyImage {...props} />)`
  max-height: 150px;
`;
const ArcImage = styled((props: any) => <GatsbyImage {...props} />)`
  width: 100%;
`;
const ImageWrapper = styled.div`
  width: 100%;
`;

const GenericCard = (props: GenericCardProps) => {
  let arcFluid = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "utils/images/Events/events-arc.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
    }
  `).file.childImageSharp.gatsbyImageData;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 175px;
    padding: 0.5rem;
    max-width: 100%;
  `;

  const CardCSS = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 325px;
    max-height: 371px;
    border-radius: 4px;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
  `;

  const MiniHeader = styled.h4`
    margin: 0px auto;
    padding-bottom: 0.5rem;
    text-align: center;
    font-family: ${quicksandFont.bold};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.2px;
  `;

  const Title = styled.div`
    margin: 0px auto;
    text-align: center;
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    color: ${colors.blue};
    div {
      font-family: Baloo;
    }
  `;

  const Description = styled.p`
    margin: 0px auto;
    max-height: 100px;
    text-align: center;
    overflow: hidden;
  `;

  const FallbackImage = styled.img`
    max-height: 150px;
    object-fit: cover;
  `;

  return (
    <ExpandOnHover>
      <CardCSS
        href={
          props.linkType === "Email" ? "mailto:" + props.linkURL : props.linkURL
        }
        target={props.linkType === "External Link" ? "_blank" : "_self"}
      >
        <ImageWrapper>
          <SuccessImage image={props.cardImage} alt="" />
          <ArcImage image={arcFluid} alt="" />
        </ImageWrapper>

        <TextContainer>
          <MiniHeader>{props.miniHeader}</MiniHeader>
          <Title>
            <DangerousHtml>{props.title}</DangerousHtml>
          </Title>

          <Description>{props.description}</Description>
          {props.linkURL && <ArrowButtonContainer>&rarr;</ArrowButtonContainer>}
        </TextContainer>
      </CardCSS>
    </ExpandOnHover>
  );
};

export interface GenericCardProps {
  linkURL?: string;
  linkType?: string;
  title: string;
  description?: string;
  miniHeader?: string;
  cardImage: IGatsbyImageData;
  external?: boolean;
}

export default GenericCard;
