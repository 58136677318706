import * as React from "react";
import styled from "@emotion/styled";
import { screenSize, colors } from "../../utils/css/themes";
import Arrow from "../../utils/images/HomePage/OurImpact/arrow.inline.svg";

const SocialLabel = styled.h2`
  font-family: Baloo;
  font-size: 32px;
  line-height: 1.19;
  letter-spacing: -1px;
  color: ${colors.blue};

  @media (min-width: ${screenSize.smallTablet}) {
    font-family: Baloo;
    font-size: 50px;
    line-height: 0.76;
    letter-spacing: -2px;
    color: ${colors.blue};
  }
`;

const ArrowContainer = styled.div`
  transform: translateX(31px) rotate(-69deg);
  @media (max-width: ${screenSize.smallTablet}) {
    transform: translateX(15px) rotate(-82deg) translateY(25px);
  }
`;

const LabelContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-left: 10rem;
  @media (max-width: ${screenSize.largeTablet}) {
    justify-content: left;
    margin-left: 2rem;
  }
`;

const Social = (props: any) => {
  function wereSocial() {
    return (
      <LabelContainer>
        <SocialLabel>
          {"We're Social!"}
        </SocialLabel>
        <ArrowContainer>
          <Arrow />
        </ArrowContainer>
      </LabelContainer>
    );
  }

  return wereSocial();
};

export default Social;
