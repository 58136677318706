import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";
import Confetti from "../../utils/images/bg-confetti.svg";
import Arrow from "../../utils/images/HomePage/MoreHighlights/arrow-more-highlights.inline.svg";
import GenericCard from "../Helpers/GenericCard";
import { useUrlParse } from "../../utils/useUrlParse";

const MoreHighlightsCurves = styled.div`
  padding: 8rem 0;
  background: ${colors.green} url(${Confetti});
  margin-bottom: -5rem;
`;

const CardContainer = styled.div`
  text-decoration: none;
  margin: auto;
  padding: 0;
  @media (min-width: ${screenSize.smallTablet}) {
    padding: 0 1rem;
  }
`;

const HighlightContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 6rem 0;
  max-width: 1160px;
  margin: auto;
  flex-direction: column;
  align-items: center;
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  @media (max-width: ${screenSize.smallTablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Label = styled.div`
  font-family: Baloo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 40px;

  line-height: 1;
  letter-spacing: -2px;
  color: #ffffff;
  margin: 0 1rem 1rem 0;
  text-align: left;
  @media (min-width: ${screenSize.largePhone}) {
    font-size: 50px;
  }
`;

const LabelArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: auto 0 2rem 0;
`;

const ArrowStyle = styled.div`
  margin: 0 auto;
  padding-right: 0rem;
  transform: scaleX(-1) rotate(150deg) translateX(-11px) translateY(22px);
`;

const MoreHighlights = (props: PropsMoreHighlights) => {
  function renderCard() {
    return props?.more_highlights?.map((highlightData: any, i: number) => (
      <CardContainer key={i}>
        <GenericCard
          linkURL={useUrlParse(highlightData.link)}
          title={highlightData.title}
          description={highlightData.description}
          miniHeader={highlightData.type}
          cardImage={
            highlightData.image?.localFile?.childImageSharp?.gatsbyImageData
          }
          fallbackImage={highlightData.source_url}
        ></GenericCard>
      </CardContainer>
    ));
  }

  return (
    <MoreHighlightsCurves>
      <HighlightContainer>
        {props?.more_highlights?.length > 0 ? (
          <>
            <LabelArrowContainer>
              <Label>{"More Highlights"}</Label>
              <ArrowStyle>
                <Arrow />
              </ArrowStyle>
            </LabelArrowContainer>
            <Cards>{renderCard()}</Cards>
          </>
        ) : (
          <p>The data for More Highlights could not be found.</p>
        )}
      </HighlightContainer>
    </MoreHighlightsCurves>
  );
};

interface PropsMoreHighlights {
  more_highlights: any;
}

export default MoreHighlights;
