import * as React from "react";
import { colors, screenSize } from "../../utils/css/themes";
import styled from "@emotion/styled";

const ArrowButton = styled.span`
  display: none;
  @media (min-width: ${screenSize.largeTablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: solid 1px ${colors.orange};
    border-radius: 50px;
    color: ${colors.orange};
    box-sizing: border-box;
    text-align: center;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 16px;
    margin: auto auto 0 auto;
    line-height: 1.25;
  }
`;

export default ArrowButton;
