import * as React from "react";
import styled from "@emotion/styled";
import { screenSize, colors } from "../../utils/css/themes";
import Arrow from "../../utils/images/HomePage/SeeUsInAction/arrow.inline.svg";

const YouTubeVideos = (props: VideoProps) => {
  // handling the curve
  const YouTubeVideosCurves = styled.div`
    padding-top: 0;
    padding-bottom: 4rem;
    background-color: white;
    overflow: hidden;
    border-bottom-right-radius: 60em 11.5em;
    border-bottom-left-radius: 31em 2.5em;
    position: relative;
    z-index: 2;
    margin-left: -2rem;
    margin-right: -5rem;
    margin-bottom: -8rem;
    padding-left: 2rem;
    padding-right: 5rem;
    @media (max-width: ${screenSize.largeTablet}) {
      border-bottom-right-radius: 100em 11.5em;
      border-bottom-left-radius: 31em 2.5em;
      margin-right: -2rem;
      padding-right: 2rem;
    }
    @media (max-width: ${screenSize.largePhone}) {
      border-bottom-left-radius: 0;
    }
  `;

  const MainVideoContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
  `;

  const StyledIFrame = styled.iframe`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
    z-index: 0;
    background-color: black;
    background-size: calc(5vw + 40px), cover;
  `;

  const IFrameContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  `;

  const VideoContainer = styled.div`
    margin: 2rem 1rem;
    display: flex;
    @media (max-width: ${screenSize.largeDisplay}) {
      display: block;
    }
  `;

  const YouTubeVideosContainer = styled.div`
    max-width: 1160px;
    margin: auto;
  `;

  const LabelContainer = styled.div`
    display: flex;
    @media (min-width: ${screenSize.largeTablet}) {
      margin-left: 25rem;
      margin-top: 4rem;
    }
    text-align: center;
    margin-top: 4rem;
    justify-content: center;

    svg {
      margin-top: 2rem;
      margin-right: 10px;
    }
  `;

  const ActionLabel = styled.h2`
    font-family: Baloo;
    font-size: 32px;
    line-height: 1.19;
    letter-spacing: -1px;
    color: ${colors.blue};
    @media (min-width: ${screenSize.smallTablet}) {
      font-family: Baloo;
      font-size: 50px;
      line-height: 0.76;
      letter-spacing: -2px;
      text-align: right;
      color: ${colors.blue};
    }
  `;

  const PrimaryVideoContainer = styled.div`
    margin: auto;
    width: 65%;
    padding-right: 1rem;
    @media (max-width: ${screenSize.largeDisplay}) {
      width: 100%;
      padding-right: 0;
    }
  `;

  const WatchMore = styled.a`
    display: flex;
    width: 25%;
    height: 40px;
    background-color: ${colors.blue};
    color: white;
    text-decoration: none;
    margin: auto;
    display: flex;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.2);
    @media (max-width: ${screenSize.largeTablet}) {
      width: 80%;
    }
    &:hover {
      color: ${colors.blue};
      background-color: white;
      cursor: pointer;
      border: 1px solid ${colors.blue};
    }
  `;

  return (
    <YouTubeVideosCurves>
      <YouTubeVideosContainer>
        <LabelContainer>
          <Arrow />
          <ActionLabel>{"See Us In Action"}</ActionLabel>
        </LabelContainer>
        <VideoContainer>
          <PrimaryVideoContainer>
            <MainVideoContainer>
              <IFrameContainer>
                <StyledIFrame
                  title="iframe"
                  width="100%"
                  height="100%"
                  src={
                    "https://www.youtube.com/embed/videoseries?list=" +
                    props.video
                  }
                ></StyledIFrame>
              </IFrameContainer>
            </MainVideoContainer>
          </PrimaryVideoContainer>
        </VideoContainer>

        <WatchMore href="https://www.youtube.com/seekidsdream">
          {"Watch more on youtube"}
        </WatchMore>
      </YouTubeVideosContainer>
    </YouTubeVideosCurves>
  );
};

interface VideoProps {
  video: string;
}

export default YouTubeVideos;
