import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GetInvolved from "../components/HomePage/GetInvolved";
import MoreHighlights from "../components/HomePage/MoreHighlights";
import OurImpact from "../components/HomePage/OurImpact";
import MainImage from "../components/HomePage/MainImage";
import YouTubeVideos from "../components/HomePage/YouTubeVideos";
import styled from "@emotion/styled";
import Social from "../components/HomePage/Social";

const IndexPage = () => {
  const homePage = useStaticQuery(graphql`
    query {
      wpPage(title: { eq: "Home" }, homePageFields: {}) {
        ...IndexQuery
      }
    }
  `).wpPage;
  const get_involved = homePage.homePageGetInvolvedBoxes?.involvements;
  const our_impact = homePage.homePageOurImpact?.impacts;
  const more_highlights = homePage.homePageMoreHighlights?.moreHighlights;

  const HideOverflow = styled.div`
    overflow: hidden;
  `;

  return (
    <Layout mainPage={true}>
      <SEO title="Home" />
      <HideOverflow>
        <MainImage
          content={homePage.content}
          video={homePage.homePageFields?.homePageVideo?.localFile?.publicURL}
          video2={
            homePage?.homePageFields?.homePageVideo2?.localFile?.publicURL
          }
          button_text={homePage.homePageFields?.buttonText}
          button_link={homePage.homePageFields?.buttonLink.link}
        />
        <GetInvolved get_involved={get_involved} />
        <YouTubeVideos video={homePage.homePageFields?.mainYoutubeVideo} />
        <OurImpact our_impact={our_impact} />
        <Social facebook={homePage.homePageFields?.twitterHandle} />
        <MoreHighlights more_highlights={more_highlights} />
      </HideOverflow>
    </Layout>
  );
};

export default IndexPage;
