import * as React from "react";
import styled from "@emotion/styled";

import SocialLabel from "./SocialLabel";
import FacebookTimeline from "../Helpers/FacebookTimeline";
import { screenSize } from "../../utils/css/themes";

// handling the curves
const WereSocialSurves = styled.div`
  padding-top: 4rem;
  padding-bottom: 6rem;
  overflow: hidden;
  border-top-left-radius: 60em 11.5em;
  border-top-right-radius: 31em 2.5em;
  border-bottom-left-radius: 60em 11.5em;
  border-bottom-right-radius: 60em 11.5em;
  position: relative;
  z-index: 5;
  margin-left: -5rem;
  margin-right: -5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: -7rem;
  margin-bottom: -11rem;
  background-color: white;

  @media (max-width: ${screenSize.largeTablet}) {
    border-bottom-left-radius: 100em 11.5em;
    border-bottom-right-radius: 0;
  }
  @media (max-width: ${screenSize.largePhone}) {
  }
`;

const TimeLines = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${screenSize.largeTablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

const SocialMain = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LabelContainer = styled.div`
  margin-left: -10rem;
`;

const FacebookContainer = styled.div`
  max-width: 100%;
  padding: 0 1rem;
`;

const Social = (props: any) => {
  return (
    <WereSocialSurves>
      <SocialMain>
        <LabelContainer>
          <SocialLabel/>
        </LabelContainer>
        <TimeLines>
          <FacebookContainer>
            <FacebookTimeline page={props.facebook} />
          </FacebookContainer>
        </TimeLines>
      </SocialMain>
    </WereSocialSurves>
  );
};

export default Social;
