import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";
import Family from "../../utils/images/HomePage/GetInvolved/icon-family.inline.svg";
import Educators from "../../utils/images/HomePage/GetInvolved/icon-educators.inline.svg";
import Supporters from "../../utils/images/HomePage/GetInvolved/icon-supporters.inline.svg";
import Volunteers from "../../utils/images/HomePage/GetInvolved/icon-volunteers.inline.svg";
import Confetti from "../../utils/images/bg-confetti.svg";
import Arrow from "../../utils/images/HomePage/GetInvolved/arrow-get-involved.inline.svg";
import ExpandOnHover from "../Helpers/ExpandOnHover";
import { useUrlParse } from "../../utils/useUrlParse";
import { Link } from "gatsby";

const InvolvedContainer = styled.div`
  padding: 11rem 10rem 6rem 10rem;
  margin-left: -10rem;
  margin-right: -10rem;
  border-bottom-right-radius: 110em 6.5em;
  border-bottom-left-radius: 248em 17.5em;
  background: ${colors.green} url(${Confetti});
  box-shadow: 0px 6px 0px white;
`;

const InvolvedContent = styled.div`
  max-width: 1160px;
  margin: auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 35% 1rem 0;
  margin-bottom: 1rem;
  margin: 0 auto;
  @media (max-width: ${screenSize.largeTablet}) {
    padding: 1rem 0;
  }
  @media (max-width: ${screenSize.smallTablet}) {
    max-width: 85%;
  }
`;

const Text = styled.h1`
  color: white;
  margin-bottom: 0;
  font-size: 50px;
`;

const ArrowContainer = styled.div`
  transform: rotate(-75deg) translateX(2px) translateY(23px);
`;

const Involved = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;

  @media (max-width: ${screenSize.largeTablet}) {
    flex-flow: row wrap;
  }

  @media (max-width: ${screenSize.largePhone}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBoxLink = styled((props: any) => <Link {...props} />)`
  background-color: ${colors.blue};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 4px 10px 40px 0 #36690e;
  width: 200px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  @media (max-width: ${screenSize.largeTablet}) {
    margin: 2%;
    flex: 1 0 calc(50% - 4%);
  }

  @media (max-width: ${screenSize.largePhone}) {
    margin: 1rem 0;
    flex-direction: row;
    width: 90vw;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colors.yellow};
    h2,
    p {
      color: ${colors.blue};
    }
    div {
      svg {
        path,
        g {
          fill: ${colors.blue};
        }
      }
    }
  }
`;

const Title = styled.h2`
  font-weight: normal;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: white;
  @media (max-width: ${screenSize.largePhone}) {
    text-align: left;
  }
`;

const Description = styled.p`
  text-align: center;
  color: white;
  font-weight: bold;
  display: flex;

  justify-content: center;
  text-transform: uppercase;
  margin: 0.75rem 0.75rem auto 0.75rem;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.58px;
  @media (max-width: ${screenSize.largePhone}) {
    text-align: left;
    margin: 0;
    justify-content: left;
  }
`;

const MainContainer = styled.div`
  @media (max-width: ${screenSize.largePhone}) {
    width: 60%;
  }
`;

const ImgContainer = styled.div`
  max-width: 60px;
  margin: auto;

  display: ${(props) => (props.hideSmall ? "none" : "flex")};
  @media (max-width: ${screenSize.largePhone}) {
    display: ${(props) => (props.hideLarge ? "none" : "flex")};
  }
  svg {
    width: 100%;
    color: white;
    g {
      fill: ${colors.yellow};
    }
  }
`;

// hard coding SVGs because not needed in CMS
const Image = (box: number): any => {
  switch (box) {
    case 0:
      return <Family />;
    case 1:
      return <Volunteers />;
    case 2:
      return <Supporters />;
    case 3:
      return <Educators />;
    default:
      return null;
  }
};

const GetInvolved = (props: PropsGetInvolved) => {
  return (
    <InvolvedContainer>
      <InvolvedContent>
        <TextContainer>
          <Text> {"Get Involved"}</Text>
          <ArrowContainer>
            <Arrow />
          </ArrowContainer>
        </TextContainer>
        <Involved>
          {props?.get_involved?.map((involvement: any, i: number) => (
            <ExpandOnHover key={i}>
              <StyledBoxLink
                to={useUrlParse(involvement.pageLink.link)}
                key={i}
              >
                <ImgContainer hideSmall>{Image(i)}</ImgContainer>
                <MainContainer>
                  <Title>{involvement.type}</Title>
                  <ImgContainer hideLarge>{Image(i)}</ImgContainer>
                  <Description>{involvement.description}</Description>
                </MainContainer>
              </StyledBoxLink>
            </ExpandOnHover>
          ))}
        </Involved>
      </InvolvedContent>
    </InvolvedContainer>
  );
};

interface PropsGetInvolved {
  get_involved: any;
}

export default GetInvolved;
